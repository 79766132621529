<template>
  <div class="common_sub_container">
    <section>
      <div class="address_book_primary_view">
        <h3 class="bulk">Upload Invoice File</h3>
            <div class="bulk_booking_courier_selecter" style="display: flex;">
              <div class="label_and_element_wrapper" style="display: flex;margin: 0 auto;">
                <div style="padding-right: 2rem;">
                  <select v-model="provider_id" @change="checkFileExist">
                      <option value="" disabled>Select Carrier </option>
                      <option v-for="(carrier,i) in carriers" :key="'carriers'+i" :value="carrier.id">{{carrier.name}}</option>
                    </select>
                </div>
          
              </div>
            </div>  

        <p>Choose the Invoice upload file. Please remember only .csv files are accepted and it should be less than 2mb in size.</p>
        <p>
          You can download sample .csv format
          <a :href="sample_url">here</a>
        </p>

        <label class="bt_upload_file">
          CHOOSE A FILE
          <input type="file" ref="myFiles" @change="handleFileUpload"  />
        </label>

         <div style="position: relative;" v-if="loading==true"><div data-v-531a3342="" style="background: #06A5ED none repeat scroll 0% 0%; padding: 8px 10px; font-weight: bold; position: absolute; left: 470px; bottom: -14px;color: white;"><img data-v-531a3342="" src="../../assets/loader.svg" style="height: 25px; float: left;"><div data-v-531a3342="" style="float: right;padding-top: 3px;margin-left: 6px;">Uploading Invoice….. Please wait</div></div></div>

      </div>

    </section>
    <section></section>
    <div class="black-overlay" v-show="showpopSelectDestination">
      <div class="box-modal smallPops">
        <div class="body">
          <popInvoiceUploadList
            :showCount="showpopSelectDestination"
            v-if="showpopSelectDestination"
            @closeModal="closeConsignment"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import popInvoiceUploadList from "./popups/popInvoiceUploadList.vue";
export default {
  name: "BulkBookingUser",
  components: {
    popInvoiceUploadList
  },
  created(){
    this.$emit("breadCrumbData",["Invoice Check","Invoice Upolad"]);
    this.sample_url = process.env.VUE_APP_API_URL + "/api/invoiceupload/sample?token="+localStorage.token;
    this.fetchCarriers();
  },
  data() {
    return {
      showpopSelectDestination:"",
      files: "",
      sample_url:"",
      error_data: [],
      loading:false,
      carriers:[],
      goods:[],
      servicetypes:[],
      good:"",
      servicetype:"",
      provider_id:"",
      
    };
  },
  methods: {
    fetchCarriers(){
      this.axios.get("/api/provider")
      .then(response => {
          this.carriers = response.data.providers;
      })
      .catch(error =>{
          this.toast.error();
      });
    },
    checkFileExist() {
      console.log(this.provider_id, this.files)
      if (this.files) {
        let formData = new FormData();
        formData.append("sample_file", this.files);
        formData.append("provider_id", this.provider_id);
        this.loading=true;
        this.axios
          .post("/api/invoiceupload/booking", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => {
            this.loading=false;
            if(response && response.data) {
              this.showpopSelectDestination = response.data.showCount;
            }
            this.toast.success(response.data.msg);
            // this.$router.go(this.$router.currentRoute);
          })
          .catch(error => {
            this.loading=false;
            // this.$router.go(this.$router.currentRoute); 
            // if (error.response && error.response.data) {
            //   this.showpopSelectDestination = error.response.data.showCount;
            // }
          });
      }
    },
    handleFileUpload() {

      this.error_data = [];
      this.files = this.$refs.myFiles.files[0];
      let formData = new FormData();
      formData.append("sample_file", this.files);
      formData.append("provider_id", this.provider_id);
      this.loading=true;
      this.axios
        .post("/api/invoiceupload/booking", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          this.loading=false;
          if(response && response.data) {
            this.showpopSelectDestination = response.data.showCount;
          }
           this.toast.success(response.data.msg);
          // this.$router.go(this.$router.currentRoute);
        })
        .catch(error => {
          this.loading=false;
          // this.$router.go(this.$router.currentRoute); 
          // if (error.response && error.response.data) {
          //   this.showpopSelectDestination = error.response.data.showCount;
          // }
        });
    },
    closeConsignment(){
      this.showpopSelectDestination = '';
      this.$router.go(this.$router.currentRoute);
    },
  }
};
</script>

<style  lang="scss"  >
.bulk_booking_courier_selecter{
  width:500px; height:auto; padding:20px 0; margin:auto;
  
}
.address_book_primary_view h3.bulk
{
  padding-bottom: 0;
}

.label_and_element_wrapper select
{
  background-position: right 5px top 50% !important;
 
}
.select_dropdown
{
width:120% !important;

}

</style>